import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NotAdminGuard, AuthGuard, GuestGuard, MaintenanceGuard, NotMaintenanceGuard, NotStagingGuard, PendingGuard, StagingGuard, PendingEmailGuard } from '@fengbrasil/ngx-fengst-auth';
import { MaintenanceComponent, PageNotFoundComponent, InternalServerErrorComponent, LogoutComponent, TermsComponent, RecoveryPasswordComponent, StagingComponent, AdminComponent, ScailingMessageComponent } from '@fengbrasil/ngx-fengst-layout';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-home').then((m) => m.HomePublicModule),
    canActivate: [StagingGuard, GuestGuard, MaintenanceGuard],
  },
  {
    path: 'home',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-home').then((m) => m.HomePrivateModule),
    canActivate: [StagingGuard, AuthGuard, MaintenanceGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'home/:page',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-home').then((m) => m.HomePublicModule),
    canActivate: [StagingGuard, MaintenanceGuard],
  },
  {
    path: 'minha-conta/meu-plano',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.PlanModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'minha-conta/meu-perfil',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.ProfileModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'minha-conta/pagamentos',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.PaymentsModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'minha-conta/dependentes',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.DependentsModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'minha-conta/sorteios',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.RafflesModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard],
  },
  { 
    path: 'minha-conta/cadeiras', 
    loadChildren: () => import('@fengbrasil/ngx-fengst-account').then(m => m.ChairsModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard]
  },
  {
    path: 'carrinho',
    redirectTo: 'checkout/register',
    pathMatch: 'full'
  },
  { 
    path: 'checkout/register', 
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.RegisterModule),
    canActivate: [StagingGuard, MaintenanceGuard, GuestGuard],
  },
  { 
    path: 'checkout/success', 
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.SuccessModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard]
  },
  { 
    path: 'checkout/subscribe/:idplano', 
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.SubscribeModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard]
  },
  {
    path: 'checkout/resubscribe/:idplano/:idcontrato',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.ResubscribeContractModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard]
  },
  { 
    path: 'checkout/upgrade/:idplano', 
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.UpgradeModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard]
  },
  { 
    path: 'checkout/address', 
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.AddressModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard]
  },
  { 
    path: 'checkout/cart/:type/:idplano', 
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.CartModule),
    canActivate: [StagingGuard, MaintenanceGuard, PendingGuard, PendingEmailGuard]
  },
  { 
    path: 'checkout/cart', 
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.CartModule),
    canActivate: [StagingGuard, MaintenanceGuard]
  },
  { 
    path: 'planos', 
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.PlansModule),
    canActivate: [StagingGuard, MaintenanceGuard]
  },
  {
    path: 'conteudo-exclusivo',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-exclusive-content').then((m) => m.MainModule),
    canActivate: [StagingGuard, MaintenanceGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'parceiros',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-partner').then((m) => m.PartnerModule),
    canActivate: [StagingGuard, MaintenanceGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'pendente',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-auth').then((m) => m.PendingModule),
    canActivate: [StagingGuard, AuthGuard],
  },
  {
    path: 'validar-cadastro/:id',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.ValidateAccountModule),
    canActivate: [MaintenanceGuard],
  },
  {
    path: 'experiencias',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-experience').then((m) => m.CatalogModule),
    canActivate: [MaintenanceGuard],
  },
  {
    path: 'experiencias/como-pontuar',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-experience').then((m) => m.HowModule),
    canActivate: [MaintenanceGuard],
  },
  {
    path: 'experiencias/extrato',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-experience').then((m) => m.PointsModule),
    canActivate: [MaintenanceGuard],
  },
  {
    path: 'experiencias/resgatados',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-experience').then((m) => m.RescuesModule),
    canActivate: [MaintenanceGuard],
  },
  {
    path: 'ingressos',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-events').then((m) => m.EventsMainModule),
      canActivate: [StagingGuard, MaintenanceGuard, PendingGuard],
  },
  {
    path: 'atendimento',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-contact').then((m) => m.ContactModule),
    canActivate: [StagingGuard, MaintenanceGuard],
  },
  {
    path: 'termos-e-politicas',
    component: TermsComponent,
    canActivate: [StagingGuard, MaintenanceGuard],
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [NotAdminGuard],
  },
  {
    path: 'staging',
    component: StagingComponent,
    canActivate: [NotStagingGuard],
  },
  {
    path: 'staging/login',
    component: StagingComponent,
    canActivate: [NotStagingGuard],
  },
  {
    path: 'recuperar-senha/:id',
    component: RecoveryPasswordComponent,
    canActivate: [StagingGuard, MaintenanceGuard],
  },
  {
    path: 'sair',
    component: LogoutComponent,
    canActivate: [StagingGuard, MaintenanceGuard],
  },
  {
    path: 'alta-demanda',
    component: ScailingMessageComponent
  },
  {
    path: '500',
    component: InternalServerErrorComponent
  },
  {
    path: 'manutencao',
    component: MaintenanceComponent,
    canActivate: [NotMaintenanceGuard],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    canActivate: [StagingGuard, MaintenanceGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }