import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;


export const fengEnv = {
  production: false,
  maintenance: false,
  // apiURL: 'http://localhost:3333/',
  apiURL: 'https://api.stg.novojaconero.feng.rocks/',
  // apiURL: 'https://api.juventude.com.br/',
  weURL: 'https://admin.stg.sociojaconero.com.br/',
  recaptcha: {
    key: '6Lev5oIjAAAAAH3fUxsgA29W2BVXtlBjpXB8d4L1', // STG
    // key: '6Lcf6YIjAAAAAAnSuR7t6bzsOnH33DIRvGAyOl1C', // PROD
  },
  auth: {
    new: false,
    social: [
    ],
    cognito: {
      enable: false,
      REGION: 'us-east-1',
      USER_POOL_ID: 'us-east-1_OQFGqtNwK', // STG
      APP_CLIENT_ID: '4e35mn1tuom2odqou6eufconfu'
    },
    adb2c: {
      enable: false,
      name: 'FENGID',
      b2cPolicies: {
        names: {
          signUpSignIn: 'B2C_1_AccountCreation',
          resetPassword: 'B2C_1_PassReset',
          editProfile: 'B2C_1_EditProfile',
        },
        authorities: {
          signUpSignIn: {
            authority: '',
          },
          resetPassword: {
            authority: '',
          },
          editProfile: {
            authority: '',
          },
        },
        authorityDomain: '',
      },
      msalConfig: {
        auth: {
          clientId: '', // This is the ONLY mandatory field that you need to supply.
          authority: '', // Defaults to "https://login.microsoftonline.com/common"
          knownAuthorities: [''], // Mark your B2C tenant's domain as trusted.
          redirectUri: '/sso', // Points to window.location.origin by default. You must register this URI on Azure portal/App Registration.
          postLogoutRedirectUri: '/', // Points to window.location.origin by default.
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
          storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge. Remove this line to use Angular Universal
        },
        system: {
          /**
           * Below you can configure MSAL.js logs. For more information, visit:
           * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
           */
          loggerOptions: {
            loggerCallback(logLevel: LogLevel, message: string) {
              console.log(message);
            },
            logLevel: LogLevel.Verbose,
            piiLoggingEnabled: false
          }
        }
      }
    }
  },
};