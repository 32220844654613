export const fengSettings = {
  name: 'stjuventude',
  version: '0.0.1',
  hmr: true,
  projectName: 'Sócio Jaconero',
  clientName: 'Esporte Clube Juventude',
  clientId: 'CNPJ 88.661.939/0001-48',
  clientPhone: '(21) 2222-2222',
  clientEmail: 'atendimento@sociojaconero.com.br',
  clientAddress: 'R. Hércules Galló, 1547 - Centro, Caxias do Sul - RS',
  cache: {
    strategy: 'explicit',
    localStorageKey: 'fengHttpCache',
    ttl: 60000 // 1min (60000) - 5min (300000) - 30min (1800000) - 30min (1800000) - 1h (3600000) - 2h (7200000) - 6h (21600000) - 12h (43200000) - 24h (86400000)
  },
  auth: {
    enableAdmin: true,
    enableSt: true,
    enableResale: false,
    enableCaptcha: true,
    verifyCPF: true,
    verifyEmail: true,
    keepConnected: true,
    admin: {
      applyTokenIn: ['auth']
    },
    loginForm: {
      title: 'Para primeiro acesso ao novo sistema, clique abaixo em Gerar nova senha para receber no seu e-mail cadastrado.',
      text: 'Email/CPF',
      forgotPass: 'Gerar nova senha',
      forgotPassText: 'Preencha com o seu e-mail ou CPF cadastrado no Sócio Jaconero e enviaremos um código de recuperação para você. O e-mail só será enviado caso o cadastro seja encontrado.',
      forgotEmailText: 'Caso você tenha esquecido o seu e-mail, entre em contato com o atendimento <a href="/atendimento">clicando aqui</a>.',
      forgotAccountLabel: 'E-mail/CPF'
    },
    firstAccess: {
      enable: false,
      migrationLogic: false,
      ask: {
        password: false,
        terms: true,
        missingData: true
      }
    },
    social: [
      { name: 'google', enabled: false },
      { name: 'facebook', enabled: false }
    ],
    register: {
      enableSt: true,
      showTerms: true,
      fields: {
        genre: false,
        clubId: false,
      },
      clubIdValidator: ''
    },
    staging: {
      enable: false,
      title: 'SEJA BEM-VINDO!',
      subtitle: '',
      description: `
        <p>
          Bloqueio de navegação para ambiente de staging <strong>em fase de teste</strong>.
        </p>
        <p>
          Para liberar navegação, adicione <strong>/login</strong> no final da URL desta página
          e efetue o login com um <strong>usuário administrativo</strong> com
          <strong>perfil 'Administrador', 'Desenvolvedor' ou 'Staging'.
        </p>
        <p>
          <strong>Após validação da funcionalidade, esta mensagem será removida.</strong>
        </p>
      `,
    },
  },
  payment: {
    creditCard: {
      maxAllowed: 3,
      allowedTypes: [
        { type: 'visa', regex: new RegExp('^4') },
        { type: 'mastercard', regex: new RegExp('^5[1-5]') },
        { type: 'american-express', regex: new RegExp('^3[47]') },
        { type: 'diners', regex: new RegExp('^30[0-5]') },
        { type: 'jcb', regex: new RegExp('^35(2[89]|[3-8][0-9])') },
        { type: 'visa-electron', regex: new RegExp('^(4026|417500|4508|4844|491(3|7))') },
        { type: 'maestro', regex: new RegExp('^(5000|5018|5020|5038|6304|6759|676[1-3])') },
        { type: 'discover', regex: new RegExp('^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)') },
        { type: 'elo', regex: new RegExp('^(401178|401179|431274|438935|451416|457393|457631|457632|504175|627780|636297|636368|655000|655001|651652|651653|651654|650485|650486|650487|650488|506699|5067[0-6][0-9]|50677[0-8]|509\d{3})') },
        { type: 'hipercard', regex: new RegExp('^(606282\d{10}(\d{3})?|3841\d{15})') },
      ],
      enableCaptcha: true,
      enableDelete: true
    },
    installments: {
      enableInstallments: false,
      multiplePayment: {
        creditCardAllowed: true,
        bankSlipAllowed: false,
        pixAllowed: false,
      },
      subdivision: {
        creditCard: false,
      }
    },
  },
  loading: {
    global: 'ring', // default | ring | orbital | pulse
    local: 'bullets' // default | bars | bullets | progress
  },
  homeSettings: {
    scroll: {
      callToAction: {
        enable: true,
        template: 8,
        titleImage: ''
      },
      hero: true,
      tickets: {
        enable: false,
        alternativeEnable: true,
        template: 3
      },
      tabs: {
        enable: true,
        template: 9
      },
      plans: {
        enable: true,
        enableColorTheme: true
        
      },
      specialPlans: {
        enabled: true
      },
      exclContent: false,
      testimony: {
        enable: false,
        template: 1
      },
      callToActionFooter: false
    },
    banner: {
      template: 1,
      className: 'NACAO'
    },
    cards: {
      enable: true,
      contentBox: true,
      experiences: false,
      plans: true,
      ticketCards: true,
      tickets: true,
      enableColorTheme: true
    },
    header: {
      legacyMsg: ``,
      templateHeader: 2,
      activeNewPlan: false,
      voucherMessage: 'PARABÉNS, VOCÊ GANHOU <strong>$MENGO</strong><br />BAIXE O APP E ATIVE O TOKEN '
    },
    enableContractStatusWarning: true
  },
  myAccount: {
    home: {
      showBannerNewLink: true
    },
    menu: {
      plan: true,
      profile: true,
      payment: true,
      dependents: false,
      chairs: true
    },
    plan: {
      template: 2,
      enableMultiplePlans: true,
      hideAutomaticRenew: true,
      ticketCard: {
        showImage: false
      },
    },
    profile: {
      partnershipPeriod: {
        withoutInterruption: false,
        firstContract: true,
      },
      hidePartneshipPeriod: false,
      complementary: {
        enabled: true,
        fields: {
          nationality: true,
          rg: true,
          civilStatus: true,
          education: true,
          residentialPhone: true,
          shirtSize: true,
          shortSize: true,
          shoeSize: true,
          income: false,
          interest: false,
          matchFrequency: true,
          hasChildren: true,
          childrenNumber: true,
          profession: false,
          company: false,
          interestWorkField: false,
          club: false,
          religion: true
        },
        options: {
          shirtSize: [
            { value: 'P', label: 'P' },
            { value: 'M', label: 'M' },
            { value: 'G', label: 'G' },
            { value: 'GG', label: 'GG' },
            { value: '3G', label: '3G' },
            { value: '4G', label: '4G' },
            { value: '7G', label: '7G' },
          ]
        }
      }
    },
    ticketCards: {
      textWarn: {
        enableForm: false,
        textInfo: `<strong>ATENÇÃO</strong> - Ative sua carteirinha clicando no botão abaixo <strong>apenas</strong> se já tiver com ela em mãos para usufruir dos benefícios`
      }
    },
    socialNetworks: {
      enabled: true
    },
  },
  partnersSettings: {
    enableMoreInfo: false,
  },
  experiencesSettings: {
    menu: {
      catalog: true,
      howToScore: true,
      points: true,
      rescued: true
    },
    enableSocioClub: true,
  },
  faqSettings: {
    faqTemplate: 8,
    mockFaq: false,
    contacts: {
      chat: {
        active: true,
        availability: 'de segunda a sexta, das 8h às 21h, e aos sábados, das 10h às 16h',
      },
      email: {
        active: true
      }
    }
  },
  headerSettings: {
    headerTemplate: 11,
    floatingLogin: false,
    enableCounter: true,
    hideCounterOnPage: ['/transparencia'],
    enableMobileMenu: true,
    hidePoints: true,
    nav: [
      { title: 'HOME', routerLink: '/home', routerLinkActiveOptions: { exact: true } },
      { title: 'MINHA CONTA', routerLink: '/minha-conta/meu-plano', routerLinkActiveOptions: { exact: false } },
      { title: 'PLANOS', routerLink: '/planos', routerLinkActiveOptions: { exact: true } },
      { title: 'INGRESSOS', routerLink: '/ingressos', routerLinkActiveOptions: { exact: true } },
      { title: 'ATENDIMENTO', routerLink: '/atendimento', routerLinkActiveOptions: { exact: true } },
    ],
  },
  footerSettings: {
    footerTemplate: 8,
    social: [
      { icon: 'twitter', href: 'https://twitter.com/ECJuventude' },
      { icon: 'instagram', href: 'https://www.instagram.com/ecjuventude' },
      { icon: 'youtube', href: 'https://www.youtube.com/channel/UCrY4s3Eq7zSa5SE1LjoW_xg' },
      { icon: 'facebook', href: 'https://www.facebook.com/juventude' }
    ],
    nav: [
      { title: 'HOME', routerLink: '/', click: '', clickParam: '', if: 'notHome' },
      { title: 'PLANOS', routerLink: '/planos', click: '', clickParam: '', if: '' },
      { title: 'INGRESSOS', routerLink: '/ingressos', click: '', clickParam: '', if: '' },
      { title: 'ATENDIMENTO', routerLink: '/atendimento', click: '', clickParam: '', if: '' },
      { title: 'ASSOCIE-SE', routerLink: '/checkout/cart', click: '', clickParam: '', if: '' },
      { title: 'LOGOUT', routerLink: '', click: 'logout', clickParam: '', if: 'isAuth' },
    ],
    enableTerms: true
  },
  plans: {
    template: 8,
    especial: true,
    cardPlaceholder: "https://ngx-feng.s3-sa-east-1.amazonaws.com/images/loading/img-placeholder.png",
    enableDependents: false,
    defaultsubscribeText: 'EU QUERO',
    customSubscribeText: 'QUERO ESTE!'
  },
  specialPlan: {
    template: 2,
  },
  xRay: {
    enableCounter: true,
    enableTabBackgroundColor: true,
    enableStates: false,
    enableCities: false,
    enableDistricts: false,
    enablePlans: true,
    enablePaymentMethod: true,
    enableAge: true,
    enableSex: true,
    enableXRay: true,
    enableXRayActionBackgroundColor: true,
    featuredTemplate: 2,
    enableFinancial: false,
    financialTemplate: 2,
    enableTransparency: false,
    tranparencyMonthsNameType: 'fullName', // fullName | shortName,
  },
  embassies: {
    template: 2
  },
  checkout: {
    register: {
      promptValidation: {
        cpf: false,
        email: false,
        clubId: false,
      }
    },
    defaultDuration: '12',
    maxGuests: false,
    showPaymentMethods: true,
    comingSoonPaymentSlip: false,
    modalSixPeriodicity: {
      enabled: false,
      title: 'ATENÇÃO',
      message: `
        Os planos com duração semestral não possuem o benefício do kit de boas-vindas.
        Você receberá apenas a carteirinha. Caso deseje receber o kit, altere a periodicidade para Anual.
      `
    },
    offState: {
      state: 'RJ',
      modalOffState: {
        enabled: false,
        title: 'ATENÇÃO',
        message: `
          Você selecionou um plano fora do estado do RS, iremos te redirecionar para página de planos para selecionar o plano correto.
        `
      }
    },
    successPage: {
      enabled: true,
      title: 'Pagamento processado!',
      message: `Seja bem vindo ao Sócio Jaconero.`
    },
    boletoPage: {
      enabled: true,
      title: 'Boleto gerado com sucesso!',
      message: `Agora falta pouco! Pague o seu boleto até a data de vencimento.`
    },
    pixPage: {
      enabled: true,
      title: 'Código PIX gerado com sucesso',
      message: `Agora falta pouco! Pague a fatura gerada através do QR Code abaixo.`
    },
    paymentFailPage: {
      enabled: true,
      title: 'Ocorreu um erro ao efetuar o seu pagamento!',
      message: `Não foi possível processar o seu pagamento na forma de pagamento selecionada.`
    },
    enableValidator: true,
    upgrade: {
      enableDiscountRule: true
    }
  },
  events: {
    shield: 'https://juventude-gs.s3.amazonaws.com/prod/assets/images/st_logo.png',
    background: 'https://juventude-gs.s3.sa-east-1.amazonaws.com/prod/assets/images/bg-ingressos-s.png',
    name: 'JUVENTUDE',
    template: 1,
    ticketing: 'SUPERINGRESSO', // Available Options: FCARD / MBILHETE / TICKETHUB / SUPERINGRESSO
    myTicketsEvent: 'cdedd3afddc34a99e655ca007247b4ca',
    integration: {
      enableTicketBalance: false
    },
    showMyTickets: true,
    showMoreInfo: true,
    showTicketsClose: true,
    showRating: true,
    showHowManyTickets: true,
    imageMoreInformation: 'https://fluminensefc.s3.amazonaws.com/prod/assets/images/maisinfo-flu.png'
  },
  terms: {
    limitCardMessage: 'Caso não tenha esse limite disponível no cartão de crédito, somente o valor da parcela será cobrado todos os meses de maneira automática. Isso não altera nenhuma condição prevista nos termos de uso.',
    splitted: false,
    termsURL: {
      url: null,
      route: '/termos-e-politicas',
      pageTitle: null,
      contentKeywords: ['termo', 'adesão']
    },
    privacyURL: {
      url: null,
      route: '/termos-e-politicas',
      pageTitle: null,
      contentKeywords: ['termo', 'adesão']
    }
  },
}
