export const fengTheme = {
    name: 'feng',
    properties: {

        '--background': '#F8F8F8',
        '--on-background': '#000',
        '--primary': '#188150',
        '--primary-RGB': '24, 129, 95',
        '--on-primary': '#fff',
        '--secondary': '#BA940F',
        '--secondary-RGB': '186, 148, 15',
        '--on-secondary': '#fff',
        '--surface': '#fff',
        '--surface-RGB': '255, 255, 255',
        '--on-surface': '#000',
        '--error': '#F25455',
        '--error-RGB': '242, 84, 85',
        '--on-error': '#fff',
        '--warning': '#EFC845',
        '--warning-RGB': '239, 200, 69',
        '--on-warning': '#fff',
        '--success': '#68DB6C',
        '--success-RGB': '104, 219, 108',
        '--on-success': '#fff',
        '--info': '#D0D0D0',
        '--info-RGB': '208, 208, 208',
        '--on-info': '#fff',

        '--max-width': '1200px',

        // Add external fonts @font-face to styles.scss
        '--font-primary': 'Roboto',
        '--font-title': 'Roboto',
        '--font-button': 'Roboto',
        '--font-event': 'Roboto',

        // Menu
        '--menu-itens-color': '--primary-light',
        '--menu-itens-background': 'transparent',
        '--menu-active-color': 'white',
        '--menu-active-background': '#BB9C2E',
        '--menu-active-underline': '0',

        '--menu-mobile-background': '#fff',
        '--menu-mobile-user-detail-color': '#404040',
        '--menu-mobile-user-detail-background': '#fff',
        '--menu-mobile-itens-color': '#404040',
        '--menu-mobile-itens-background': '#fff',

        // Header
        '--header-margin': '',
        // '--header-bg': 'url(https://fluminensefc.s3.amazonaws.com/prod/assets/images/header-flu.svg) no-repeat center center / cover',
        '--header-bg': '#01492C',
        // '--header-mobile-bg': 'url(https://fluminensefc.s3.amazonaws.com/prod/assets/images/header-flu.svg) no-repeat center center / auto',
        '--header-mobile-bg': '#01492C',
        '--header-mobile-logo-width': '100px',
        '--header-desktop-logo-width': '240px',
        '--header-mobile-height': '102px',
        '--header-desktop-height': '186px',
        '--header-mobile-font-size': '14px',
        '--header-counter-font-family': 'Roboto',
        '--header-counter-text-color': '#01492C',
        '--header-counter-number-font-family': 'Roboto',
        '--header-counter-number-outline-color': 'none',
        '--header-counter-background': '#13AC7B',
        '--header-counter-number-bg': '#18815F',
        '--header-desktop-font-size': '16px',
        '--header-mobile-margin-top': '0',
        '--header-desktop-margin-top': '0',
        '--header-mobile-logo-top': '-20px',
        '--header-desktop-logo-top': '-20px',
        '--public-header-security-top-padding': '0',
        '--private-header-security-top-padding': '0',
        '--header-logged-bg': '#18815F',
        '--header-logged-mobile-logo-width': '100px',
        '--header-logged-desktop-logo-width': '240px',

        // Home Public
        // Call to Action
        '--home-call-to-action-font-family': 'Roboto',
        '--home-call-to-action-background': 'no-repeat center right / cover',
        '--home-call-to-action-background-img': 'https://juventude-gs.s3.sa-east-1.amazonaws.com/stg/assets/images/tickets-back.png',
        '--home-call-to-action-background-opacity': '1',
        '--home-call-to-action-detail-color': '#fff',
        '--home-call-to-action-title-font-size': '75px',
        '--home-call-to-action-title-font-family': 'Apocalypse-Grunge',
        '--home-call-to-action-title-desktop-max-width': '870px',
        '--home-call-to-action-title-font-weight': 'bold',
        '--home-call-to-action-title-margin': '0 auto 54px',
        '--home-call-to-action-title-line-height': '120%',
        '--home-call-to-action-title-alternate-font-family': 'VarsityTeam-Bold',
        '--home-call-to-action-title-alternate-font-size': '110px',
        '--home-call-to-action-subtitle-font-size': '21px',
        '--home-call-to-action-subtitle-line-height': '28px',
        '--home-call-to-action-subtitle-width': '500px',
        '--home-call-to-action-content-padding': '2% 0',
        '--home-call-to-action-content-align': 'center',
        '--home-call-to-action-button-margin': '37px',
        '--home-call-to-action-button-color': '#BA940F',
        '--home-call-to-action-hover-button-color': 'var(--background)',
        '--home-call-to-action-button-background': 'var(--background)',
        '--home-call-to-action-button-padding': '15px 64px',
        '--home-call-to-action-button-font-size': '19px',
        '--home-call-to-action-button-font-family': 'Ernesto',
        '--home-call-to-action-button-style': 'normal',
        '--home-call-to-action-margin-button': '50px 0 0',
        '--home-call-to-action-9-primary-font-family': 'Roboto',
        '--home-call-to-action-9-primary-button-font-weight': '700',
        '--home-call-to-action-9-description-font-size': '50px;',
        '--home-call-to-action-9-descriptionn-line-height': '60px',
        '--home-call-to-action-9-description-text-align': 'start',
        '--mobile-home-call-to-action-title-margin': '0 auto 30px',
        '--mobile-home-call-to-action-content-padding': '0 10% 50px',
        '--mobile-home-call-to-action-title-font-size': '40px',
        '--mobile-home-call-to-action-title-line-height': '120%',
        '--mobile-home-call-to-action-title-alternate-font-family': 'VarsityTeam-Bold',
        '--mobile-home-call-to-action-title-alternate-font-size': '62px',
        '--call-to-action-margin-top': '0',
        '--call-to-action-padding-top': '30px',

        // Tabs
        '--home-desktop-tabs-1-background': 'no-repeat center center / cover',
        '--home-desktop-tabs-1-background-img': '#F8F8F8',
        '--home-desktop-tabs-2-background': 'no-repeat center center / cover',
        '--home-desktop-tabs-2-background-img': '#F8F8F8',
        '--home-desktop-tabs-3-background': 'no-repeat center center / cover',
        '--home-desktop-tabs-3-background-img': '#F8F8F8',
        '--home-desktop-tabs-9-background': 'no-repeat center center / cover',
        '--home-desktop-tabs-9-background-bg': 'url(https://juventude-gs.s3.sa-east-1.amazonaws.com/prod/assets/images/NovaHome/s/img1.png)',
        '--home-desktop-tabs-9-background-bg-shield': 'url(https://juventude-gs.s3.sa-east-1.amazonaws.com/prod/assets/images/NovaHome/s/img2.png)',
        '--home-desktop-tabs-9-background-bg-flag':'url(https://juventude-gs.s3.sa-east-1.amazonaws.com/prod/assets/images/NovaHome/s/img3.png)',
        '--home-desktop-tabs-9-button-color': '#BA940F',
        '--home-desktop-tabs-9-title-font-family': 'Apocalypse Grunge',
        '--home-desktop-tabs-9-primary-font-family': 'Roboto',
        '--home-desktop-tabs-9-primary-button-font-weight': '500',
        '--home-desktop-tabs-9-description-font-size': '50px;',
        '--home-desktop-tabs-9-descriptionn-line-height': '0',
        '--home-desktop-tabs-9-description-text-align': 'center',

        // Home Private
        '--home-private-padding-top': '0',
        '--home-private-featured-height-tablet': '248px',
        '--home-private-featured-height-mobile-410': '133px',
        '--home-private-featured-height-mobile-380': '122px',
        '--home-private-plan-box-title-color': '#037345',
        '--home-private-cards-title-color': '#BA940F',

        // Plans
        '--plans-background': '#f8f8f8',

        // My Profile
        '--my-profile-title-color': '#037345',
        '--my-profile-name-color': '#037345',
        '--my-profile-subtitle-color': '#037345',
        '--my-profile-card-font-weight': '300',
        '--my-profile-text': '#27292D',
        '--my-profile-button': '#BA940F',
        '--my-profile-header': '#FFE794',

        // Contact
        '--contact-background': 'url(https://juventude-gs.s3.sa-east-1.amazonaws.com/stg/assets/images/tickets-back.png) no-repeat center center / cover',

        // Experiences
        '--experience-how-to-score-icon-filter': '',

        // Exclusive Content
        '--exclusive-content-background-RGB': '0,0,0',
        '--exclusive-content-background': 'white',
        '--exclusive-content-text': 'var(--primary)',

        // Events
        '--event-background-select': '#18815F',
        '--event-background-table': '#f8f8f8',
        '--event-color-button': '#BA940F',
        '--event-color-button-primary': '#BA940F',
        '--event-color-button-secondary': '#037345',
        '--event-transparent-button': '#037345',
        '--event-button-border-color': '#f8f8f8',
        '--event-color-text-button': '#ffffff',
        '--event-color-text-default': '#f8f8f8',
        '--event-color-text-light': '#f8f8f8',
        '--event-color-text-dark': '#01492C',
        '--event-color-text-primary': '#000',
        '--event-color-text-secondary': '#BA940F',

        // Footer
        '--footer-item-margin': '25px auto',
        '--footer-color': '#45ae6f',
        '--footer-background': '#ffffff',
        '--footer-logo-width': '150px',

        // Auth Modal
        '--auth-modal-stlogo-width': '180px',
        '--auth-modal-stlogo-bg': '#006739',
        '--auth-modal-closebtn-color': 'black',
        '--mobile-auth-modal-closebtn-color': 'white',
        '--mobile-auth-modal-stlogo-width': '100px',
        '--mobile-auth-modal-stlogo-margin': '10px 0',
        '--first-access-bg': '#006739',

        // Pending E-mail
        '--pending-mail-bg': 'white',
        '--pending-mail-color': 'black',
        '--pending-mail-logo-height': '100px',
        '--pending-mail-logo-bg': '#00321c',

        // Staging
        '--staging-logo-background-color': '#006739',

        // Maintenance
        '--maintenance-logo-background-color': '#006739',
    },
    images: {
        'stLogo': 'https://juventude-gs.s3.amazonaws.com/prod/assets/images/st_logo.png',
        'stLogoMobile': 'https://juventude-gs.s3.amazonaws.com/prod/assets/images/st_logo.png',
        'stLogoFooter': 'https://juventude-gs.s3.amazonaws.com/prod/assets/images/st_logo.png',
        'loadingLogo': 'https://juventude-gs.s3.amazonaws.com/prod/assets/images/st_logo.png',
        'stShield': 'https://juventude-gs.s3.amazonaws.com/prod/assets/images/st_logo.png',
        '--homePlansBG': 'url(https://juventude-gs.s3.sa-east-1.amazonaws.com/stg/assets/images/tickets-back.png)',
        '--homeTicketsBG': 'url(https://juventude-gs.s3.sa-east-1.amazonaws.com/stg/assets/images/tickets-back.png)',
        '--homeExclusiveContentBG': 'url(https://juventude-gs.s3.sa-east-1.amazonaws.com/stg/assets/images/tickets-back.png)',
        'mailPendingLogo': 'https://juventude-gs.s3.amazonaws.com/prod/assets/images/st_logo.png',
    },
    texts: {
        // Home
        // Call to Action
        // 'callToActionFeatured': '<strong>NOVOS PLANOS,</strong><BR><strong>NOVOS BENEFÍCIOS</strong><br><span>E O MESMO SENTIMENTO</span><BR><span>VERDE, BRANCO E GRENÁ!</span>',
        'callToActionFeatured': '<strong class="__flu-h1">NOVOS PLANOS,</strong><BR><strong class="__flu-h2">NOVOS BENEFÍCIOS</strong><br><span class="__flu-h3">E O MESMO SENTIMENTO</span><BR><span class="__flu-h4">VERDE, BRANCO E GRENÁ!</span>',
        'callToActionFeaturedImg': '',
        // 'callToActionDetail': 'FAÇA PARTE DO TIME DE<br> GUERREIROS E TENHA DESCONTOS <br>E EXPERIÊNCIAS EXCLUSIVAS',
        'callToActionDetail': '',
        'callToActionButton': 'SEJA SÓCIO',

        // Auth
        // First Access
        'firstAccessText': 'Identificamos que este é o seu primeiro acesso ao nosso novo sistema. Para continuar, você deve aceitar o Termos de Uso e Política de Privacidade. Aproveite também para atualizar os seus dados cadastrais!',
        // Recover Pass
        'recoverPassText': 'Para definir a sua nova senha, digite o código de recuperação recebido por e-mail, além de sua nova senha.',

        // Ticket Cards
        'ticketCardText': 'Cartão de Sócio',
        'ticketCardsText': 'Cartões de Sócio'
    }
}
